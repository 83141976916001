import React from "react"

import {Link} from "gatsby";

import siteSettings from "../constants/site-settings";

import Logo from "../images/icons/logo-white.svg";
import InstagramIcon from "../images/icons/instagram-icon.svg";
import FacebookIcon from "../images/icons/facebook-icon.svg";
import GoogleIcon from "../images/icons/google-icon.svg";
import TwitterIcon from "../images/icons/twitter-icon.svg";
import YoutubeIcon from "../images/icons/youtube-icon.svg";

import './footer.scss'


function FooterComponent() {
  return (
    <footer className="footer md:py-0 w-full">
      <div
        className="container w-full h-full mx-auto flex flex-col md:flex-row place-content-between items-center px-5 py-10 md:py-0 relative">
        <div className="flex flex-row items-center w-full md:w-auto justify-between">
          <Link to="/" className="footer-logo">
            <Logo className="white"/>
          </Link>
          <div className="flex md:hidden flex-row items-center">
            <a className="mr-6" href={siteSettings.instagramLink} target="_blank" rel="noopener noreferrer">
              <InstagramIcon className="white"/>
            </a>
            <a className="mr-6" href={siteSettings.facebookLink} target="_blank" rel="noopener noreferrer">
              <FacebookIcon className="white"/>
            </a>
            <a className="mr-6" href={siteSettings.googleLink} target="_blank" rel="noopener noreferrer">
              <GoogleIcon className="white"/>
            </a>
            <a className="mr-6" href={siteSettings.twitterLink} target="_blank" rel="noopener noreferrer">
              <TwitterIcon className="white" />
            </a>
            <a href={siteSettings.youtubeLink} target="_blank" rel="noopener noreferrer">
              <YoutubeIcon className="white" />
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:flex-1 h-full items-center justify-center md:pb-5">
          <Link to="/" className="nav-link my-3 md:my-0 md:mr-12" activeClassName="active">
            Main page
          </Link>
          <Link to="/services" className="nav-link my-3 md:my-0 md:mr-12" activeClassName="active">
            Service
          </Link>
          <Link to="/contacts" className="nav-link my-3 md:my-0" activeClassName="active">
            Contacts
          </Link>
        </div>
        <div className="hidden lg:flex flex-row gap-6 items-center justify-end tablet-socials">
          <a className="mr-6" href={siteSettings.instagramLink} target="_blank" rel="noopener noreferrer">
            <InstagramIcon className="white" />
          </a>
          <a className="mr-6" href={siteSettings.facebookLink} target="_blank" rel="noopener noreferrer">
            <FacebookIcon className="white" />
          </a>
          <a className="mr-6" href={siteSettings.googleLink} target="_blank" rel="noopener noreferrer">
            <GoogleIcon className="white" />
          </a>
          <a className="mr-6" href={siteSettings.twitterLink} target="_blank" rel="noopener noreferrer">
            <TwitterIcon className="white" />
          </a>
          <a href={siteSettings.youtubeLink} target="_blank" rel="noopener noreferrer">
            <YoutubeIcon className="white" />
          </a>
        </div>
        <div className="copyright mt-6 md:mt-0 mb-10 md:mb-0 w-full">
          © 2023 GDB Garage Door Repair INC. All Rights Reserved.
        </div>
      </div>
    </footer>
  )
}

export default FooterComponent
