import React, {useState} from "react"

import { Link } from "gatsby"

import Logo from "../images/icons/logo-white.svg";
import InstagramIcon from "../images/icons/instagram-icon.svg";
import FacebookIcon from "../images/icons/facebook-icon.svg";
import GoogleIcon from "../images/icons/google-icon.svg";
import TwitterIcon from "../images/icons/twitter-icon.svg";
import YoutubeIcon from "../images/icons/youtube-icon.svg";
import PhoneIcon from "../images/icons/phone-white.svg";
import CaretDown from "../images/icons/caret-down.svg";

import siteSettings from "../constants/site-settings";
import areas from "../constants/areas.json";


import './header.scss'

function HeaderComponent() {
  const [isOpen, setIsOpen] = useState(false);
  const [isServiceMenuOpened, setIsServiceMenuOpened] = useState(false);

  function menuClick() {
    setIsOpen(!isOpen);
  }

  function serviceMenuClick() {
    setIsServiceMenuOpened(!isServiceMenuOpened);
  }

  return (
    <header className="header relative w-full">
      <div className="container mx-auto h-full flex place-content-between items-center px-5 gap-5">
        <div className="md:hidden">
          <button type="button" className={`hamburger ${isOpen ? 'opened' : ''}`} onClick={menuClick}>
            <span />
            <span />
            <span />
          </button>
        </div>
        <div className="flex flex-row items-center">
          <Link to="/" className="md:mr-8">
            <Logo className="logo white"/>
          </Link>
          <div className="hidden lg:flex">
            <a className="mr-6" href={siteSettings.instagramLink} target="_blank" rel="noopener noreferrer">
              <InstagramIcon className="white" />
            </a>
            <a className="mr-6" href={siteSettings.facebookLink} target="_blank" rel="noopener noreferrer">
              <FacebookIcon className="white" />
            </a>
            <a className="mr-6" href={siteSettings.googleLink} target="_blank" rel="noopener noreferrer">
              <GoogleIcon className="white" />
            </a>
            <a className="mr-6" href={siteSettings.twitterLink} target="_blank" rel="noopener noreferrer">
              <TwitterIcon className="white" />
            </a>
            <a href={siteSettings.youtubeLink} target="_blank" rel="noopener noreferrer">
              <YoutubeIcon className="white" />
            </a>
          </div>
        </div>
        <div className="hidden md:flex flex-row gap-12 items-center">
          <Link to="/" className="nav-link" activeClassName="active">
            Main page
          </Link>
          <Link to="/services" className="nav-link" activeClassName="active">
            Service
          </Link>
          <button type="button" className={`nav-link dropdown ${isServiceMenuOpened ? 'active opened' : ''}`} onClick={serviceMenuClick}>
            Service area
            <CaretDown className="caret"/>
          </button>
          <Link to="/contacts" className="nav-link" activeClassName="active">
            Contacts
          </Link>
        </div>
        <div className="flex-row items-center">
          <a href={siteSettings.phoneNumberLink} className="gdb-btn primary get-in-touch hidden lg:flex" aria-label="Get in touch">Call us</a>
          <a href={siteSettings.phoneNumberLink} className="gdb-btn primary get-in-touch flex lg:hidden" aria-label="Get in touch">Call us</a>
        </div>
      </div>
      <div className={`mobile-menu overflow-hidden w-full px-5 ${ isOpen ? 'opened' : '' } ${ isServiceMenuOpened ? 'service-opened' : '' }`}>
        <div className="flex flex-col gap-5 py-5">
          <div className="links flex flex-col items-start gap-5">
            <Link to="/" className="nav-link" activeClassName="active">
              Main page
            </Link>
            <Link to="/services" className="nav-link" activeClassName="active">
              Service
            </Link>
            <Link to="/contacts" className="nav-link" activeClassName="active">
              Contacts
            </Link>
            <button type="button" className={`nav-link dropdown ${isServiceMenuOpened ? 'active opened' : ''}`} onClick={serviceMenuClick}>Service area <CaretDown className="caret"/></button>
            <div className={`dropdown-menu w-full ${isServiceMenuOpened ? 'opened' : ''}`}>
              <div className="grid grid-cols-1 px-5 py-3 w-full">
                {areas.map((area) => (
                  <Link key={area.path} to={`/${area.path}`} className="area-link" activeClassName="active">
                    {area.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="socials flex justify-between">
            <a href={siteSettings.instagramLink} target="_blank" rel="noopener noreferrer">
              <InstagramIcon className="white" />
            </a>
            <a href={siteSettings.facebookLink} target="_blank" rel="noopener noreferrer">
              <FacebookIcon className="white" />
            </a>
            <a href={siteSettings.googleLink} target="_blank" rel="noopener noreferrer">
              <GoogleIcon className="white" />
            </a>
            <a href={siteSettings.twitterLink} target="_blank" rel="noopener noreferrer">
              <TwitterIcon className="white" />
            </a>
            <a href={siteSettings.youtubeLink} target="_blank" rel="noopener noreferrer">
              <YoutubeIcon className="white" />
            </a>
          </div>
        </div>
      </div>
      <div className={`service-area-menu ${isServiceMenuOpened ? 'opened' : ''}`}>
        <div className="container mx-auto">
          <div className="grid grid-cols-4 xl:grid-cols-6 gap-x-10 px-5">
            {areas.map((area) => (
              <Link key={area.path} to={`/${area.path}`} className={`area-link ${area.main ? 'highlight' : ''}`} activeClassName="active">
                {area.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </header>
  )
}
export default HeaderComponent
