const siteSettings = {
  siteUrl: 'https://gdb-repair.com',
  siteTitle: 'GDB Garage Door',
  siteDescription: 'Residential Garage Doors service & sales in Chicagoland community. For 24/7 Garage Door solutions. Call 773-956-1198.',
  image: 'https://www.gdb-repair.com/main.webp',
  shareImageWidth: '630',
  shareImageHeight: '451',

  phoneNumberLink: 'tel:+17739561198',
  phoneNumberPretty: '(773) 956-1198',
  email: 'garagedoorb.repair@gmail.com',
  instagramLink: 'https://www.instagram.com/gdbgaragedoor',
  facebookLink: 'https://www.facebook.com/GDB.GarageDoorRepair',
  googleLink: 'https://www.google.com/search?authuser=1&_ga=2.269047668.429320832.1631209127-1101020064.1622240643&q=GDB+Garage+Door+Repair&ludocid=11364441486128983608&lsig=AB86z5XpCf7eSX7pXqEZszjoRPCl',
  twitterLink: 'https://twitter.com/DoorGdb',
  youtubeLink: 'https://www.youtube.com/channel/UCK8N5Pkvm2G_RarsfRfPjYQ ',
  googleCommentsLink: 'https://www.google.com/maps/place/GDB+Garage+Door+Repair/@41.6766013,-88.6237396,9z/data=!4m7!3m6!1s0x0:0x9db69b51fb6e2e38!8m2!3d41.6779625!4d-88.063308!9m1!1b1',
  getformUrl: 'https://getform.io/f/24d7cf29-0cb9-4ff6-95f2-742ebeadd2ad',
  phoneNumberButtonText: 'Call 773-956-1198',
}

export default siteSettings
